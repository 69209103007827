body {
  margin: 0;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
}

html,
body {
  height: 100%;
  background-color: #1e1e1e;
  color: #fff;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
}
