.homePage {
  background: linear-gradient(85.46deg,
      #0d080a 9.38%,
      rgba(10, 8, 13, 0.7) 45.24%,
      rgba(0, 0, 0, 0.3)),
    linear-gradient(180deg, #000, rgba(0, 0, 0, 0) 62.5%), #3c0713;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
}

.homePage:has(.overlay) {
  height: 100vh;
}

.tilesContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row dense;
  gap: 16px;
  height: 100%;
  padding: 16px;
  align-items: start;
  position: relative;
  justify-content: start;
}

.tilesContainer:has(.overlay) {
  overflow: hidden;
}

.blocked {
  pointer-events: none;
  opacity: 0.5;
}

.tilesContainer> :nth-child(odd):last-child {
  grid-column: span 2;
}

.spinnerContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  --spinner-color: rgba(250, 30, 78, 1);
  --border-color: rgba(36, 32, 49, 1);
  width: 70px;
  height: 70px;
  animation: spinner 1s linear infinite forwards;
  border: 10px solid var(--border-color);
  border-left: 10px solid var(--spinner-color);
  border-radius: 50%;
  display: block;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlayContent {
  color: white;
  text-align: center;
}

.overlayContent h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
}

.overlayContent p {
  font-size: 17px;
  font-weight: 300;
}