.page {
    background: linear-gradient(85.46deg,
            #0d080a 9.38%,
            rgba(10, 8, 13, 0.7) 45.24%,
            rgba(0, 0, 0, 0.3)),
        linear-gradient(180deg, #000, rgba(0, 0, 0, 0) 62.5%),
        #3c0713;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.topBar {
    position: relative;
    background-color: #121019;
    height: 72px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    gap: 16px;
    flex-shrink: 0;
    text-align: center;
    color: #fff;
}

.backIcon {
    width: 24px;
    height: 24px;
}

.topBarTitle {
    margin-right: auto;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.panel {
    background: rgba(18, 16, 25, 0.8);
    border: 1px solid #4a3e65;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    flex: 1 1 auto;
    max-width: 100%;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    gap: 24px;
    overflow-y: auto;
}

.panel pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
}