.info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabsCount {
  margin-left: 10px;
  color: #fa1d4e;
  font-size: 28px;
}

.mainIconStyle {
  width: 36px;
  height: 30px;
  position: relative;
}

.label {
  margin-top: 10px;
  color: #fff;
  font-size: 17px;
}
