.items {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.item {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

.header {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
}

.title {
  flex: 1;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
}

.resetButtonContainer {
  width: 50%;
  display: flex;
  justify-content: center;
}

.footer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-size: 8px;
  color: #bdb8d1;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.logoHorizontal {
  width: 100px;
  height: 25px;
}

.versionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.version {
  font-size: 8px;
  line-height: 18px;
  text-transform: capitalize;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.licenseIcon {
  width: 14px;
  height: 14px;
}

.disclaimer {
  font-size: 6px;
  color: #5a517b;
  text-transform: capitalize;
  text-align: center;
  padding: 0 16px;
}