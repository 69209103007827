.primary {
  background-color: #fa1d4e;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0 8px;
  height: 52px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 590;
  width: 100%;
  box-sizing: border-box;
}

.disabled {
  background-color: #201d2b;
  border: 1px solid #2e273f;
  color: #7c7c81;
  cursor: auto;
}
