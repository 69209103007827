.connectPage {
  background: linear-gradient(85.46deg,
      #0d080a 9.38%,
      rgba(10, 8, 13, 0.7) 45.24%,
      rgba(0, 0, 0, 0.3)),
    linear-gradient(180deg, #000, rgba(0, 0, 0, 0) 62.5%), #3c0713;
  overflow-y: auto;
  height: 100vh;
  display: grid;
}

.videoContainer {
  width: 100vw;
}

.button {
  justify-self: center;
  width: 60%;
}
