.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-size: 8px;
  color: #bdb8d1;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.logoHorizontal {
  width: 100px;
  height: 25px;
}

.versionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.version {
  font-size: 8px;
  line-height: 18px;
  text-transform: capitalize;
}

.notices {
  width: 100%;
  position: relative;
  font-size: 15px;
  line-height: 20px;
  font-family: Inter;
  color: #5a517b;
  white-space: pre-wrap;
  text-align: left;
  display: inline-block;
}