.tile {
  display: flex;
  height: 251px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(18, 16, 25, 0.8);
  border: 1px solid #2e273f;
  border-radius: 8px;
  padding: 16px;
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.enabled {
  background: rgba(74, 62, 101, 0.5);
  border: 1px solid #4a3e65;
}

.top {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  flex: 1;
  color: #fff;
  font-size: 13px;
  padding: 0 8px;
  text-transform: uppercase;
}

.icon {
  width: 24px;
  height: 24px;
}

.mainIcon {
  width: 74px;
  height: 60px;
}

.status {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  color: #fa1d4e;
}

.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
