.topBar {
  position: relative;
  background-color: #121019;
  height: 72px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  gap: 16px;
  flex-shrink: 0;
  text-align: center;
  color: #fff;
}

.logo {
  height: 32px;
  width: 32px;
}

.title {
  margin-right: auto;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.statusContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.statusIcon,
.settingsIcon {
  width: 24px;
  height: 24px;
}

.settingsLink {
  display: flex;
  align-items: center;
}

@media (max-width: 410px) {
  .topBar {
    justify-content: start;
    gap: 8px;
  }

  .title {
    flex-shrink: 0;
  }

  .statusContainer {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
