.toggle {
  width: 40px;
  height: 20px;
  border-radius: 25px;
  background: rgba(18, 16, 25, 0.8);
  border: 2px solid #2e273f;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.toggle.enabled{
  background: #4a1223;
  border: 2px solid #ea1d4a;
}

.button {
  width: 16px;
  height: 16px;
  background: #bab9d1;
  border-radius: 50%;
  border: 1px solid #4a3e65;
  position: absolute;
  top: 1.3px;
  left: 1px;
  transition: left 0.1s ease-in;
}

.button.enabled {
  left: 21px;
  height: 16px;
}
